import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import _ from 'lodash';
import slugify from 'slugify';
import Link from 'components/base/Link';

/**
 * Format a date
 * @param  {date} date The date, formatted as javascript `new Date()`
 * @return {string}    The date formatted as a string
 */
export const formatDate = date => {
  if (date instanceof Date) {
    return `${('0' + (date.getMonth() + 1)).slice(-2)}/${(
      '0' + date.getDate()
    ).slice(-2)}/${date.getFullYear()}`;
  } else return '';
};

/**
 * Rich Text Formatter
 * @param  {json} text The json object that contains text blocks, from contentful
 * @return {jsx}       Formatted JSX of the text output
 */
export const richText = text => {
  return documentToReactComponents(text, richTextOptions);
};

// The rich text options
const richTextOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return <Link to={node.data.uri}>{children}</Link>;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [MARKS.BOLD]: (node, children) => <strong>{children}</strong>,
    [MARKS.ITALIC]: (node, children) => <em>{children}</em>,
    [MARKS.UNDERLINE]: (node, children) => (
      <span style={{ textDecoration: 'underline' }}> {children} </span>
    )
  }
};

/**
 * cleanupValues
 * @param  {array} valuesArray [an array of values]
 * @return {array}             [a cleaned up array of values]
 *
 * This gets rid of annoying stuff in the values that we dont want
 */
export const cleanupValues = valuesArray => {
  valuesArray = _.filter(valuesArray, value => value.count > 400);
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'name'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'medium'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'accession number'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'identifying number'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'username'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'product name'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'author'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'title'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'language'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'model'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'compatibility'
  );
  valuesArray = _.filter(
    valuesArray,
    value => value.name.toLowerCase() !== 'notes'
  );
  valuesArray.sort((a, b) => {
    return b.count - a.count;
  });
  valuesArray.forEach(item => {
    item.types.sort((a, b) => {
      return b.count - a.count;
    });
    item.types = _.filter(
      item.types,
      value => value.name.toLowerCase() !== 'n/a'
    );
    item.types = _.filter(
      item.types,
      value => value.name.toLowerCase() !== 'na'
    );
    item.types = _.filter(
      item.types,
      value => value.name.toLowerCase() !== 'not listed'
    );
    item.types = _.filter(
      item.types,
      value => value.name.toLowerCase() !== 'unknown'
    );
    item.types = item.types.slice(0, 6);
  });
  valuesArray = valuesArray.slice(0, 10);
  return valuesArray;
};

/**
 * Array shuffle helper
 *
 * https://stackoverflow.com/a/2450976/1726887
 *
 * @param  {array} array [an array of stuff to shuffle]
 * @return {array}       [a shuffled array]
 */
export const shuffle = array => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

/**
 * Random item from array
 * @param  {Array} arr [an array]
 * @return {???}       [an item from the array]
 */
export const randomFromArray = arr => {
  return arr[Math.floor(Math.random() * arr.length)];
};

/**
 * Slugify helper
 *
 * @param  {String} str [the string to slugify]
 * @return {String}     [the slugified string]
 */
export const slug = str => {
  return slugify(str, { lower: true, strict: true });
};
